import { useRuntimeConfig } from '#app';
import { useUrlSearchParams } from '@vueuse/core';

export const useEnvVar = (env_var: string) => {
    const config = useRuntimeConfig();
    const params = useUrlSearchParams('history');

    const canOverride = !config.public.IS_PROD || config.public.FORCE_ENV_OVERRIDE;

    if (canOverride) {
        const keys = Object.keys(params);
        if (keys.includes(env_var)) {
            return params[env_var];
        }
    }

    return config.public[env_var];
};
